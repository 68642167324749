import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface ComponentProps {
  urlPrefix: string;
}

const Payment = () => {

  const iframeRef = useRef<HTMLIFrameElement | null>(null);;
  const [iframeHeight, setIframeHeight] = useState('0px');

  useEffect(() => {
      const handleResize = () => {
        if (iframeRef) {
          try  {
            const contentHeight = iframeRef.current?.contentWindow?.document.documentElement.scrollHeight;;
            console.log(contentHeight);
            setIframeHeight(contentHeight + 'px');
          } catch {
            setIframeHeight('550px');
          }
        }
      };

      // Initial height calculation
      setIframeHeight('550px');

      // Add resize event listener
      window.addEventListener('resize', handleResize);

      // Cleanup
      return () => window.removeEventListener('resize', handleResize);
    }, []);


    const location = useLocation();
    const fullUrl = `/payment-page${location.pathname}`;

    return (
      <>
        <iframe      
            ref={iframeRef}
            className='Payment-Iframe' 
            width="100%"
            height={iframeHeight}
            src={fullUrl} 
            title='Payment Frame' />
      </>
    );
}

export default Payment;
