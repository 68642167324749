import logo from '../../logos/logo.svg';

const Header = () => {
    return (
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    );
}

export default Header;
