import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Payment from './components/payment';
import PaymentComplete from './components/paymentcomplete';
import Header from './components/header';
import Footer from './components/footer';

function App ()  {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="*" element={<Payment />} />
          <Route path="/payment-complete" element={<PaymentComplete />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
